import { InfoVideoStore } from "./InfoVideoStore";
import { RootStore } from '../../stores/RootStore';
import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Grid, Image } from 'semantic-ui-react';

interface IStores {
    InfoVideoStore: InfoVideoStore;
}

interface IInfoVideoDetailsPageProps {
	RootStore?: RootStore;
    titleString?: string;
    videoUrl?: string;
    pictureUrl?: string;
    description?: string | JSX.Element;
    children?: JSX.Element;
}

class InfoVideoDetailsPage extends React.Component<IInfoVideoDetailsPageProps> {
    private stores: IStores;

    constructor(props: any) {
        super(props);

        this.stores = { InfoVideoStore: new InfoVideoStore(this.props.RootStore.offerId, this.props.RootStore)}
    }

    public render() {

        const { titleString, videoUrl, pictureUrl, description, children } = this.props
        return (
            <div>
                <Provider {...this.stores}>
                    <div>
                        <Grid>
                            <Grid.Row centered>                                
                                <Grid.Column centered width={16}>
                                    <div style={{fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '25px' :'35px', fontWeight: 'bold', color: 'rgb(104, 110, 169)', textAlign: 'center',  lineHeight: '35px'}}>
                                        {titleString}
                                    </div>
                                    {videoUrl && (
                                        <div style={{textAlign: 'center', marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? 30 : 50}}>
                                            <video width={this.stores.InfoVideoStore.RootStore.isMobile ? 300 : 600} controls>
                                                <source src={videoUrl} type="video/mp4"></source>
                                            </video>
                                        </div>                                            
                                    )}
                                    {pictureUrl && (
                                        <div style={{textAlign: 'center', marginTop: 30}}>
                                            <Image src={pictureUrl} style={{width: this.stores.InfoVideoStore.RootStore.isMobile ? '300px' : '600px', marginLeft: 'auto', marginRight: 'auto'}}/>
                                        </div>
                                    )}
                                    {description && (
                                        <div style={{marginTop: this.stores.InfoVideoStore.RootStore.isMobile ? '20px' : '50px', color: 'rgb(167, 169, 192)', fontSize: this.stores.InfoVideoStore.RootStore.isMobile ? '16px' : '19px', textAlign: (titleString === "6. Egyéni előfizetői minőséggel kapcsolatos nyilatkozat" || titleString === "19. ÁSZF-igény") ? 'center' : 'justify', width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                                            {description}
                                        </div>    
                                    )}
                                    {children && (
                                        <div style={{marginTop: '20px'}}>
                                            <Grid.Row centered>
                                                <Grid.Column width={5}>
                                                    {children}
                                                </Grid.Column>                                                
                                            </Grid.Row>                                            
                                        </div>    
                                    )}                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Provider>
            </div>
        )
    }
}

export default inject('RootStore')(observer(InfoVideoDetailsPage));