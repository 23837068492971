import { LoginResponse } from "../models/login";
import { Role } from "../models/role";
import { User } from "../models/user";
import { UserInRole } from "../models/userInRole";

export class UserService {

    public async GetCurrentUser(): Promise<User> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`api/infovideouser/GetCurrentUser`, options)
        
            return response.json();
        
    }

    public async Login(LoginGuid: string, UserName: string, Password: Date): Promise<LoginResponse> {

        let postData =  {loginGuid: LoginGuid, email: UserName, birthDate: Password };

        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };
        const response = await fetch(`api/infovideouser/Login`, options)
        return response.json();

    }
    
    public async GetRolesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`api/infovideouser/GetRolesForDDL`, options)
        return response.json();
    }
    public async GetRoles(): Promise<Array<Role>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`api/infovideouser/GetRoles`, options)
        return response.json();
    }

    public async GetUserInRoles(): Promise<Array<UserInRole>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`api/infovideouser/GetUserInRoles`, options)
        return response.json();
    }

    public async GetUserByQueryText(queryText: string): Promise<Array<User>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            
        }; 

        const response = await fetch(`api/infovideouser/GetUserByQueryText?queryText=`+queryText, options)
        return response.json();
    }

    public async AddUserToRole(UserInRole: UserInRole): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(UserInRole)
        };
        const response = await fetch(`api/infovideouser/AddUserToRole`, options)
        return response

    }

    public async RemoveuserFormRole(UserInRole: UserInRole): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(UserInRole)
        };
        const response = await fetch(`api/infovideouser/RemoveuserFormRole`, options)
        return response

       

    }
    public async logOut(): Promise<boolean> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };
        const response = await fetch(`api/infovideouser/logOut`, options)
        return response.json();

    }

    public async GetAccountStatesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`api/infovideouser/GetAccountStatesForDDL`, options)
        return response.json();
    }

    public async GetSupervisorApprovedsForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`api/infovideouser/GetSupervisorApprovedsForDDL`, options)
        return response.json();
    }

    public async SaveUser(userVM:User): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(userVM)
       
        };

        const response = await fetch('api/infovideouser/SaveUser', options)
        return response;
    }

    public async GetUsers(): Promise<User[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('api/infovideouser/GetUsers', options)
        return response.json();
    }

    public async GetUserById(Id:number): Promise<User> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('api/infovideouser/GetUserById?Id='+Id, options)
        return response.json();
    }

    public async CheckUserNameIsNotUnique(userNameQuery: string): Promise<boolean> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };
        const response = await fetch(`api/infovideouser/CheckUserNameIsNotUnique?userNameQuery=`+userNameQuery, options)
        return response.json();

    }

    
}