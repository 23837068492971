import { action, computed, flow, makeAutoObservable, makeObservable, observable } from 'mobx';
import { LoginResponse } from '../models/login';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

export class RootStore {
	isUserAuthed: boolean = false;
	LoginGuid: string = "";
	UserName: string = null;
	Password: Date = null;
	UserService: UserService;
	loadingCount: number = 0;
	loadings: string[] = [];
	currentUser: User = null;
	isIsInited: boolean = false;
	loginFailure: boolean = false;
	loginFailMessage: string = "";
	isInfoVideoLogin: boolean = true;
	isInfoVideo: boolean = false;
	offerId: number;
	isMobile: boolean = false;

	constructor() {
		makeAutoObservable(this, {
			isUserAuthed: observable,
			LoginGuid: observable,
			UserName: observable,
			Password: observable,
			loadingCount: observable,
			loadings: observable,									
			currentUser: observable,	
			offerId: observable,			
			isMobile: observable,
			isIsInited: observable,
			loginFailure: observable,
			loginFailMessage: observable,
			isInfoVideoLogin: observable,
			isInfoVideo: observable,
			onChangeUserName: action,
			onChangePassword: action,
			// infovideoLogin: action,
			logOut: flow,
			infovideoLogin: flow,
			getCurrentUser: flow,
			isAuthed: computed,
		});

		this.UserService = new UserService();
		// this.getCurrentUser();
		this.isIsInited = true;
		this.checkDeviceIsMobile();
	}
	*getCurrentUser() {

		const response: User = yield this.UserService.GetCurrentUser();
		if (response.AspNetUserId) {
			this.currentUser = response;
		}
		this.isIsInited = true;
	}

	onChangeUserName = (event: any, data: any) => {
		this.UserName = data.value
		console.log(this.UserName)
	}

	onChangePassword = (event: any, data: any) => {
		this.Password = data.value
		console.log(this.Password)
	}
	addloading = (loading: string) => {
		console.log("ADD: " + loading);
		this.loadings.push(loading);
		this.loadingCount++;
	}
	removeLoading = (loading: string) => {
		console.log("Remove: " + loading);
		this.removeFromArray(this.loadings, loading);
		this.loadingCount--;
	}
	removeFromArray = (array: any[], element: any) => {
		var index = array.indexOf(element);
		if (index > -1) {
			array.splice(index, 1);
		}
	}

	checkDeviceIsMobile = () => {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			this.isMobile = true;	
		}
	}

	*infovideoLogin() {
		
		this.addloading('login');
		let result: LoginResponse = yield this.UserService.Login(this.LoginGuid, this.UserName, this.Password);

		this.LoginGuid = "";

		if (result && result.Result == true) {
			this.isInfoVideoLogin = false;

			this.isUserAuthed = true;
			this.isInfoVideo = true;
			this.offerId = result.OfferId;

			// const response: User = yield this.UserService.GetCurrentUser();
			// this.currentUser = response;			
			console.log('User authorized:', this.isUserAuthed)
			console.log(result)
			this.removeLoading('login');

		}
		else if (result.Result == false) {
			this.loginFailure = true;
			this.loginFailMessage = "Hibás felhasználónév vagy jelszó!";
			console.log('failed to login')
			this.removeLoading('login');
		}
		
		// else if (result == "lockedOut") {
		// 	this.loginFailure = true;
		// 	this.loginFailMessage = "Felhasználó letiltva!";
		// 	console.log('failed to login')
		// 	this.removeLoading('login');
		// }
	}
	*logOut() {
		var result: string = yield this.UserService.logOut()
		if (result) {
			this.currentUser = null;
		}
	}

	get isAuthed() {
		return this.isUserAuthed;
	}

}
