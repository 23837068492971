import { InfoVideoAnswer, InfoVideoResponse } from "../models/infovideoAnswer";

export class InfoVideoService {

    public async SaveInfoVideoAnswer(answer: InfoVideoAnswer): Promise<number> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(answer)
        };
        const response = await fetch(`api/infoVideo/SaveInfoVideoAnswer`, options)
        return response.json()       
    }
    public async getWaterMarkedVersion(offerId: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };
        
        window.location.assign(`/api/infoVideo/getWaterMarkedVersion?offerId=` + offerId)
        // window.location.assign(response.body as any);
    }
    public async hasWaterMarkedVersion(offerId:number): Promise<InfoVideoResponse> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('api/infoVideo/HasWaterMarkedVersion?offerId='+offerId, options)
        return response.json();
    }
    public async CloseInfoVideoAnswer(offerId: number): Promise<number> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(offerId)
        };
        const response = await fetch(`api/infoVideo/CloseInfoVideoAnswer`, options)
        return response.json()       
    }
    
    public async GetInfoVideoByOfferId(offerId:number): Promise<InfoVideoResponse> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('api/infoVideo/GetInfoVideoByOfferId?offerId='+offerId, options)
        return response.json();
    }
}