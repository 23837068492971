import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/semantic-ui-css/semantic.css";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry, SetFilterModule } from "@ag-grid-enterprise/all-modules";
import { LicenseManager } from "@ag-grid-enterprise/core";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

LicenseManager.setLicenseKey(

  "CompanyName=ShareLine Informatikai Szolgltat Korltolt Felelssg Tr,LicensedGroup=Shareline,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-024512,ExpiryDate=5_March_2023_[v2]_MTY3Nzk3NDQwMDAwMA==ebc757d4d7be09c71b2e72cdb2c2eb9e"

);

ModuleRegistry.registerModules([ClientSideRowModelModule, SetFilterModule, InfiniteRowModelModule]);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
