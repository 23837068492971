import { makeAutoObservable } from "mobx";

export class InfoVideoAnswer
{
    Id: number;
    OfferId: number;
    Step: number;
    Answer: number;
    Created: Date;
    Index: number;

    constructor(step: number, offerId: number) {
        makeAutoObservable(this);
        this.Step = step;
        this.OfferId = offerId;
    }
}

export class InfoVideoResponse{
    Answers: InfoVideoAnswer[] = [];
    OfferExist: boolean;
    Closed: boolean;
    NeedTwentyTwo:boolean;
}

export class ContentItems{
    key: number;
    title: string;
    checked: boolean;
    answer: InfoVideoAnswer
    isActive: boolean;
    isRequired: boolean;    

    constructor() {
        makeAutoObservable(this);

    }
}