import { observer, Provider } from 'mobx-react';
import React, { createRef } from 'react';
import './App.css';
import InfoVideoLoginPage from './pages/infovideo/InfoVideoLoginPage';
import InfoVideoPage from './pages/infovideo/InfoVideoPage';
import { RootStore } from './stores/RootStore';
import { UserService } from './services/user.service'
import { makeObservable } from 'mobx';
import logo from '../src/assets/logo.png'
import { Image} from 'semantic-ui-react';

interface IAppProps {
}

class App extends React.Component<IAppProps> {
	pageRef = createRef();
	RootStore: RootStore;
	UserSevice: UserService
	constructor(props: any) {
		super(props);

		this.RootStore = new RootStore();
		// this.UserSevice = new UserService();

		makeObservable(this, {
			// getLogin: flow,
		
		})
	}

	componentDidMount(): void {
		// this.getLogin();		
		// this.getCurrentUser();
		// console.log('App componentDidMount')
	
	}

	public render() {
		
		if (!this.RootStore.isIsInited) {
			return null;
		}
			
		if (this.RootStore.isInfoVideoLogin) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<InfoVideoLoginPage></InfoVideoLoginPage>
					</div>
				</Provider>
			)
		}

		if (this.RootStore.isInfoVideo) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<InfoVideoPage></InfoVideoPage>
					</div>
					{this.RootStore.loadingCount > 0 &&
							<div >	
								<div className="blur"></div>
								<div className="progress" style={{ padding: '5px' }}>
									<div style={{ background: 'white' }}>
										<Image src={logo} style={{ width: '289px' }} />
									</div>
	
								</div>
							</div>	
						}	
				</Provider>
			)
		}

		return <div></div>;
		
	}
}

export default observer(App);
